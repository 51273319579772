.table {
    padding: 0;
    background: $__white;
    .table>:not(:first-child) {
        border-top: 0;
    }
    th{
        white-space: $__nowrap;
        border-top: 1px solid $__sea__gray;
        @include margin-padding(null, 1rem 0.75rem);
    }
    td{
        border-top: 1px solid $__sea__gray;
        white-space: $__nowrap;
        vertical-align: $__middle;
        @include margin-padding(null, .5rem);
        a {
            color: $__gray;
        }
        .pro-avatar {
            height: 1.65rem;
            margin-right: 5px;
            width: $__auto;
            @include rounded(0.25rem);
        }  
        h2 {
            display: $__inline__block;
            font-size: $__font__size__14;
            font-weight: $__regular;
            @include margin-padding(0, 0);
            vertical-align: $__middle;
            a {
                color: $__gray;
            }
        }  
        a {
            color: $__gray;
        }       
    }
    >:not(:first-child) {
        border-top:0;
      }
}
.user-add-shedule-list {
    h2{
        a{
            @include margin-padding(null, 10px);
            display: $__inline__block;
            span {
                color: $__dark__gray;
                display: $__block;
                font-size: $__font__size__12;
                margin-top: 3px;
            }
        }
    }
    a{
        border: 1px dashed $__light__battle__gary;
        color: $__light__battle__gary;
        border-radius: 5px;
        display: $__inline__block;
        padding: 10px;
    }
}
.table-bordered{
    td{
        border: 1px solid $__light__gary;
    }
     th {
        border: 1px solid $__light__gary;
    }
} 
.filter-row {
    .select2-container {
        .select2-selection.select2-selection--single {
            height: 50px;
        }
    }
    .btn {
        min-height: 50px;
        text-transform: $__uppercase;
        @include margin-padding(null, 12px);
    }
}
.card-table {
    .card-header {
        border-bottom: 1px solid $__light__goose;
        padding: 15px;
    }
    .card-body {
        padding: 0;
    }
    .card-footer {
        text-align: $__center;
        a {
            color: $__gray;
        }
    }
   
}
.dropdown-action {
    .dropdown-toggle{
        &::after{
            border: 0;
        }
    }
}
.action-icon {
    color: $__dark__battle__gary;
    font-size: $__font__size__18;
    display: $__inline__block;
}
.table-inbox {
    td{
        padding: 0.5rem ;
    }
    .starred{
        &.fa-star {
            color: $__yellow;
        }
    }
}
.unread{
    .name,.subject,.maildate{
        color: $__black;
        font-weight: $__semibold;
    }
} 
.table-striped{
    tbody{
        tr{
            &:nth-of-type(2n+1) {
                background-color: $__dark__light;
            }
        }
    }
}
.custom-table {
    tr.holiday-completed {
        color: $__light__battle__gary;
    }
    td{
        @include margin-padding(null, 10px 20px);
    }
}
.leave-table {
    .l-name {
        width: 200px;
    }
    .l-days {
        width: 140px;
    }
}
.review-section {
    @include margin-padding(0 0 30px, null);
    .review-header {
        background-color: $__white;
        border: 1px solid $__blue__angel;
        text-align: $__center;
        @include margin-padding(0, 15px);
        h3 {
            font-size: 1.4rem;
            margin-bottom: 3px;
        }
    }
}
.form-control-sm{
    &.form-control {
        height: 30px;
        appearance: $__auto;
        @include rounded(0);
    }
}
.note-editor.note-frame .note-statusbar {
    border-top: 0;
}
.bootstrap-datetimepicker-widget {
    font-size: 13px;
}
.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #7638ff;
}
.bg-primary-light {
    background-color: rgba(17, 148, 247, 0.12) !important;
    color: #2196f3 !important;
}
.bg-danger-light {
    background-color: rgb(255 218 218 / 49%) !important;
    color: #FF0000 !important;
}
.bg-purple-light {
    background-color: rgba(197, 128, 255, 0.12) !important;
    color: #c580ff !important;
}
.bg-default-light {
    background-color: rgba(40, 52, 71, 0.12) !important;
    color: #283447 !important;
}
.bg-success-light {
    background-color: #E1FFED !important;
    color: $__success__light !important;
}
.card-two {
    border: 1px solid #DBDFEA;
    border-radius: 5px;
}
.badge-soft-primary {
    color: #405189;
    background-color: rgba(64,81,137,.1);
}
.badge-soft-secondary {
    color: #3577f1;
    background-color: rgba(53,119,241,.1);
}
.badge-soft-success {
    color: #0ab39c;
    background-color: rgba(10,179,156,.1);
}
.badge-soft-info {
    color: #299cdb;
    background-color: rgba(41,156,219,.1);
}
.badge-soft-warning {
    color: #f7b84b;
    background-color: rgba(247,184,75,.1);
}
.badge-soft-danger {
    color: #f06548;
    background-color: rgba(240,101,72,.1);
}
.badge-soft-dark {
    color: #212529;
    background-color: rgba(33,37,41,.1);
}
.badge-soft-light {
    color: #f3f6f9;
    background-color: rgba(243,246,249,.1);
}
.badge-outline-primary {
    color: #405189;
    border: 1px solid #405189;
    background-color: $__transparent;
}
.badge-outline-secondary {
    color: #3577f1;
    border: 1px solid #3577f1;
    background-color: $__transparent;
}
.badge-outline-success {
    color: #0ab39c;
    border: 1px solid #0ab39c;
    background-color: $__transparent;
}
.badge-outline-info {
    color: #299cdb;
    border: 1px solid #299cdb;
    background-color: $__transparent;
}
.badge-outline-warning {
    color: #f7b84b;
    border: 1px solid #f7b84b;
    background-color: $__transparent;
}
.badge-outline-danger {
    color: #f06548;
    border: 1px solid #f06548;
    background-color: $__transparent;
}
.badge-outline-dark {
    color: #212529;
    border: 1px solid #212529;
    background-color: $__transparent;
}
.badge-outline-light {
    color: #f3f6f9;
    border: 1px solid #f3f6f9;
    background-color: $__transparent;
}
.badge-gray-outline {
    background-color: $__white;
    border: 1px solid $__alice__blue;
    color: gray;
}
.badge-gradient-primary {
    background: linear-gradient(135deg,#405189 0,#0ab39c 100%);
}
.badge-gradient-secondary {
    background: linear-gradient(135deg,#3577f1 0,#299cdb 100%);
}
.badge-gradient-success {
    background: linear-gradient(135deg,#0ab39c 0,#f7b84b 100%);
}
.badge-gradient-danger {
    background: linear-gradient(135deg,#f06548 0,#3577f1 100%);
}
.badge-gradient-warning {
    background: linear-gradient(135deg,#f7b84b 0,#eb990a 100%);
}
.badge-gradient-info {
    background: linear-gradient(135deg,#299cdb 0,#0ab39c 100%);
}
.badge-gradient-dark {
    background: linear-gradient(135deg,#212529 0,#405189 100%);
}
li.previous {
    margin-right: 10px;
}
.card-title {
    margin-bottom: 0;
    @include rounded(10px);
}
.cal-icon {
    position: relative;
    width: 100%;
    &:after {
        color: #979797;
        content: "\f073";
        display: $__block;
        font-family: "Font Awesome 5 Free";
        font-weight: $__bold;
        font-size: $__font__size__15;
        margin: $__auto;
        position: $__absolute;
        right: 15px;
        top: 10px;
    }
}
.cal-icon-info {
    &:after {
        font-family: "Feather";
        content: "\e926";
        color: gray;
        font-weight: $__regular;
        font-size: $__font__size__18;
        top: 8px;
    }
}

